import React from 'react';
import Index from '../../container/Index';

export default function Loading() {
    return (
        <>
            <Index.TableBody>
                <Index.TableRow>
                    <Index.TableCell colSpan={14} align="center">
                        <Index.CircularProgress color="secondary" size={20} />
                    </Index.TableCell>
                </Index.TableRow>
            </Index.TableBody>

        </>
    )
}