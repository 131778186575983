import React from 'react'

function ViewIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_23324_2)">
        <path d="M23.7484 11.1679C20.9197 6.92562 17.5388 2.99973 12 2.99973C6.46126 2.99973 3.08031 6.92562 0.251607 11.1679C0.0875376 11.4144 0 11.7039 0 12C0 12.2961 0.0875376 12.5856 0.251607 12.8321C3.08031 17.0744 6.46126 21.0003 12 21.0003C17.5388 21.0003 20.9197 17.0744 23.7484 12.8321C23.9125 12.5856 24 12.2961 24 12C24 11.7039 23.9125 11.4144 23.7484 11.1679ZM12 18.0002C8.81681 18.0002 6.33236 16.3025 3.31616 12C6.33236 7.69763 8.81681 5.99983 12 5.99983C15.1832 5.99983 17.6677 7.69763 20.6839 12C17.6677 16.3025 15.1832 18.0002 12 18.0002Z" fill="white"/>
        <path d="M12 9C11.4067 9 10.8266 9.17595 10.3333 9.50559C9.83994 9.83524 9.45543 10.3038 9.22836 10.8519C9.0013 11.4001 8.94189 12.0033 9.05765 12.5853C9.1734 13.1672 9.45912 13.7018 9.87868 14.1213C10.2982 14.5409 10.8328 14.8266 11.4147 14.9424C11.9967 15.0581 12.5999 14.9987 13.1481 14.7716C13.6962 14.5446 14.1648 14.1601 14.4944 13.6667C14.8241 13.1734 15 12.5933 15 12C14.9989 11.2047 14.6825 10.4422 14.1201 9.87986C13.5578 9.31748 12.7953 9.00106 12 9Z" fill="white"/>
        </g>
        <defs>
        <clipPath id="clip0_23324_2">
        <rect width="24" height="24" fill="white"/>
        </clipPath>
        </defs>
    </svg>

  )
}

export default ViewIcon